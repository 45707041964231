<template>
  <div class="page-competition-schedule">
    <div style="margin-bottom: 16px">
      <el-button type="primary" @click="showAdd">添加日程</el-button>
    </div>
    <pro-table
      ref="tableRef"
      :columns="columns"
      :fetch="fetch"
      :datapager="false"
    ></pro-table>
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="600px">
      <pro-form
        ref="formRef"
        label-width="120px"
        label-suffix=":"
        :model="model"
        :fields="fields"
        :rules="rules"
        :submitter="{
          submitButtonProps: {
            loading
          },
          submitText: '保存',
          resetText: '取消'
        }"
        @submit="submit"
        @reset="dialogVisible = false"
      ></pro-form>
    </el-dialog>
  </div>
</template>

<script>
import { uid } from 'uid'
import Upload from '@/components/upload'
import { localStore } from '@/utils/store'

export default {
  data() {
    return {
      dialogTitle: '',
      dialogVisible: false,
      loading: false,
      jsId: this.$route.params.id,
      record: null,
      columns: [
        {
          label: '标题(中文)',
          prop: 'title',
          render(_, text) {
            return text || '-'
          }
        },
        {
          label: '标题(英文)',
          prop: 'titleEn',
          render(_, text) {
            return text || '-'
          }
        },
        {
          label: '图标',
          prop: 'logoImg',
          render(_, val) {
            if (!val) return '-'
            return (
              <el-image
                style="width: 100px; height: 100px"
                src={val}
                preview-src-list={[val]}
              ></el-image>
            )
          }
        },
        {
          label: '备注(中文)',
          prop: 'scheduleTime',
          render(_, text) {
            return text || '-'
          }
        },
        {
          label: '备注(英文)',
          prop: 'scheduleTimeEn',
          render(_, text) {
            return text || '-'
          }
        },
        {
          label: '操作',
          width: '160px',
          render: (h, record) => {
            return (
              <div>
                <el-button type="text" onClick={() => this.toUpdate(record)}>
                  修改
                </el-button>
                <el-popconfirm
                  title="确认删除吗？"
                  onConfirm={() => this.toDelete(record)}
                >
                  <el-button
                    style="margin-left: 10px"
                    slot="reference"
                    type="text"
                  >
                    删除
                  </el-button>
                </el-popconfirm>
              </div>
            )
          }
        }
      ],
      model: {
        startTime: undefined,
        scheduleTime: '',
        scheduleTimeEn: '',
        title: '',
        titleEn: '',
        logoImgFile: null
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            label: '标题(中文)',
            prop: 'title'
          },
          style: {
            width: '360px'
          }
        },
        {
          type: 'input',
          formItemProps: {
            label: '标题(英文)',
            prop: 'titleEn'
          },
          style: {
            width: '360px'
          }
        },
        {
          type: 'date-picker',
          formItemProps: {
            label: '时间',
            prop: 'startTime'
          },
          attributes: {
            valueFormat: 'yyyy-MM-dd'
          },
          style: {
            width: '360px'
          }
        },
        {
          formItemProps: {
            label: '图标',
            prop: 'logoImgFile'
          },
          render(h, prop, field, model) {
            return (
              <Upload
                v-model={model[prop]}
                token={localStore.get('dashboard-token')}
              ></Upload>
            )
          }
        },
        {
          type: 'input',
          formItemProps: {
            label: '备注(中文)',
            prop: 'scheduleTime'
          },
          style: {
            width: '360px'
          }
        },
        {
          type: 'input',
          formItemProps: {
            label: '备注(英文)',
            prop: 'scheduleTimeEn'
          },
          style: {
            width: '360px'
          }
        }
      ],
      rules: {
        name: [{ required: true, message: '标题(中文)', trigger: 'change' }],
        nameEn: [{ required: true, message: '标题(英文)', trigger: 'change' }]
      }
    }
  },
  methods: {
    fetch() {
      return this.$serve.jsSchedule
        .getPageListAdmin({
          data: {
            jsId: this.jsId
          }
        })
        .then(([, { records, ...res }]) => {
          return {
            ...res,
            list: records
          }
        })
    },
    showAdd() {
      this.dialogTitle = '添加日程'
      this.dialogVisible = true
      this.record = null
    },
    toUpdate(record) {
      this.dialogTitle = '修改日程'
      this.dialogVisible = true
      this.record = record
      this.model = {
        scheduleTime: record.scheduleTime,
        title: record.title,
        titleEn: record.titleEn,
        startTime: record.startTime || undefined,
        logoImgFile: record.logoImg
          ? {
              uid: uid(),
              status: 'success',
              url: record.logoImg
            }
          : null
      }
    },
    async toDelete(record) {
      const [err] = await this.$serve.jsSchedule.delete({
        queryId: record.id
      })
      if (err) return
      this.$message.success('日程删除成功')
      this.$refs.tableRef.fetchData()
    },
    async submit({ logoImgFile, ...data }) {
      const params = {
        ...data,
        jsId: this.jsId
      }
      if (logoImgFile) {
        params.logoImg = logoImgFile.response?.data || logoImgFile?.url
      }
      if (this.record) {
        this.update(params)
      } else {
        this.add(params)
      }
    },
    async add(data) {
      this.loading = true
      const [err] = await this.$serve.jsSchedule.add({
        data
      })
      this.loading = false
      if (err) return
      this.$message.success('日程添加成功')
      this.dialogVisible = false
      this.$refs.tableRef.refresh()
      this.$refs.formRef.reset()
    },
    async update(data) {
      this.loading = true
      const [err] = await this.$serve.jsSchedule.update({
        data: {
          ...data,
          id: this.record.id
        }
      })
      this.loading = false
      if (err) return
      this.$message.success('日程修改成功')
      this.dialogVisible = false
      this.$refs.tableRef.refresh()
      this.$refs.formRef.reset()
    }
  }
}
</script>

<style lang="scss" scoped></style>
