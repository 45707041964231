var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-competition-schedule"},[_c('div',{staticStyle:{"margin-bottom":"16px"}},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.showAdd}},[_vm._v("添加日程")])],1),_c('pro-table',{ref:"tableRef",attrs:{"columns":_vm.columns,"fetch":_vm.fetch,"datapager":false}}),_c('el-dialog',{attrs:{"title":_vm.dialogTitle,"visible":_vm.dialogVisible,"width":"600px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('pro-form',{ref:"formRef",attrs:{"label-width":"120px","label-suffix":":","model":_vm.model,"fields":_vm.fields,"rules":_vm.rules,"submitter":{
        submitButtonProps: {
          loading: _vm.loading
        },
        submitText: '保存',
        resetText: '取消'
      }},on:{"submit":_vm.submit,"reset":function($event){_vm.dialogVisible = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }